.eurekaHighlights__container {
  padding: 200px 18px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.eurekaHighlights__title {
  font-size: 44px;
  color: var(--main-color);
  margin-bottom: 24px;
}

.eurekaHighlights__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0;
  list-style: none;
}

.eurekaHighlights__list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 0;
  width: 25%;
}

.eurekaHighlights__list-item-image {
  width: 40%;
}

.eurekaHighlights__list-item-paragraph {
  text-align: center;
  font-size: 18px;
}

@media (max-width: 680px) {
  .eurekaHighlights__container {
    padding: 50px 9px 0;
  }

  .eurekaHighlights__list {
    flex-direction: column;
  }

  .eurekaHighlights__list-item {
    width: 100%;
  }
}
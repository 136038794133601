.eurekaHero__container {
  padding: 12px;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding-top: 200px;
}

.eurekaHero__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.eurekaHero__content img {
  max-width: 50%;
}

.eurekaHero__content-text {
  font-size: 18px;
  text-align: center;
  max-width: 600px;
}

.eurekaHero__image-container {
  width: 70%;
  max-width: 600px;
}

.eurekaHero__image {
  width: 100%;
  height: auto;
}

@media (max-width: 680px) {
  .eurekaHero__container {
    flex-direction: column;
  }
}

.eurekaVision__container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #76BEF9;
  padding: 44px 18px
}

.eurekaVision__title {
  color: var(--main-color);
  font-size: 44px;
  margin-bottom: 24px;
}

.eurekaVision__info {
  color: white;
  text-align: center;
  font-size: 32px;
  max-width: 1100px;
}

@media (max-width: 680px) {
  .eurekaVision__info {
    font-size: 24px;
  }
}
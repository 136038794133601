.offer-section__container {
  margin: 0 auto;
  width: fit-content;
  padding: 100px 0 200px;
}

.offer-section__content {
  background-size: cover;
  overflow: hidden;
  padding: 10px 50px;
  z-index: 2;
}

.offer-section__slogan-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  width: 60%;
  max-width: 700px;

  background-color: var(--white-color);
  color: black;
  padding: 32px 16px;
  top: 50%;
  transform: translateY(-50%);
  left: 350px;
}

.offer-section__slogan {
  font-size: 32px;
  line-height: 170%;
}

.offer-section__page-title {
  color: var(--main-color);
  padding-bottom: 20px;
  font-size: 3rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}

.offer-section__image-wrapper {
  padding: 10px 50px;
  position: relative;
}

.offer-section__image {
  width: 570px;
  height: auto;
}

.offer-section__list {
  font-size: 2rem;
  font-weight: 300;
  padding: 50px 0 0;
}

.offer-section__list-element,
.offer-section__list-element.active {
  transition-duration: 0.4s;
  transition-delay: 0.1s;
}

.offer-section__list-element {
  list-style: none;
  margin: 20px 0;
  padding-left: 10px;
  border-left: 1px solid;
  transform: translateX(-100%);
}

.offer-section__list-element.active {
  transform: translateX(0%);
}

.offer-section__list-element--even {
  text-align: right;
  padding-right: 10px;
  border-right: 1px solid;
  border-left: none;
  transform: translateX(100%);
}

@media (max-width: 1000px) {
  .offer-section__container {
    display: block;
    padding-bottom: 100px;
  }

  .offer-section__slogan-wrapper {
    text-align: left;
    position: static;
    transform: none;
    padding: 24px 12px;
    line-height: 140%;
    width: 100%;
  }

  .offer-section__image-wrapper {
    position: relative;
  }
  
  .offer-section__image {
    width: 100%;
    height: auto;
  }

  .offer-section__content {
    width: 90%;
    margin: 0 auto;
    padding: 70px 0;
  }

  .offer-section__list-element {
    color: var(--white-color);
    border-color: var(--white-color);
    margin: 20px 0;
  }
}

@media (max-width: 680px) {
  .offer-section__list-element {
    font-size: 1.8rem;
  }

  .offer-section__page-title {
    font-size: 2.6rem;
  }
}

@media (max-width: 500px) {
  .offer-section__content {
    width: 90%;
    margin: 0 auto;
    padding: 50px 20px;
  }
  .offer-section__slogan {
  font-size: 20px;
  }

  .offer-section__list {
    padding: 20px 0 0;
  }
}

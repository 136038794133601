.about-section__container {
  padding: 100px 20px 70px;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.about-section__slogan-wrapper {
  background-color: #76bef9;
  margin-top: 80px;
  padding: 70px 30px;
}

.about-section__slogan {
  font-size: 32px;
  color: var(--white-color);
  text-align: center;
  font-weight: 500;
}

.about-section__slogan-title {
  margin-bottom: 18px;
  text-align: center;
  font-size: 45px;
  color: var(--main-color);
}

.about-section__content-container {
  display: flex;
  align-items: center;
  gap: 150px;
}

.about-section__logo-container {
  width: 200px;
}

.about-section__logo {
  width: 100%;
  height: auto;
}

.about-section__content {
  max-width: 1000px;
  padding: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}

.about-section__page-title {
  text-align: right;
  padding-bottom: 20px;
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--main-color);
}

.about-section__text {
  text-align: left;
  font-size: 1.8rem;
  font-weight: 300;
  transform: translateX(200%);
  transition-duration: 0.4s;
  transition-delay: 0.1s;
  max-width: 600px;
}

.about-section__text.active {
  transform: translateX(0%);
  transition-duration: 0.4s;
  transition-delay: 0.1s;
}

@media (max-width: 900px) {
  .about-section__content-container {
    flex-direction: column;
    gap: 20px
  }
}

@media (max-width: 500px) {
  .about-section__slogan-wrapper {
    padding: 50px 20px;
  }
  .about-section__slogan {
    font-size: 28px
  }

  .about-section__logo-container {
    width: 150px;
  }

  .about-section__slogan-title {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .about-section__text {
    font-size: 1.6rem;
  }

  .about-section__content {
    padding: 15px;
  }

  .about-section__container {
    padding: 50px 15px;
  }
}

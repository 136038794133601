.hero-section__container {
  height: 100vh;
  width: 100%;
  background: url("zdj_eou_glowne.webp");
  /* background-color: rgba(66, 23, 118, 0.43); */
  background-color:black;
  background-size: cover;
  background-position: center;
}

.hero-section__button-container {
  max-width: var(--container-max-width);
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.hero-section__slogan {
  font-size: 60px;
  font-weight: 600;
  letter-spacing: 1.4px;
  color: var(--white-color);
  margin-bottom: 64px;
  max-width: 800px;
}

.hero-section__arrow-icon {
  font-size: 24px;
  margin-left: 5px;
  transition: 0.2s;
  border: 3px solid var(--white-color);
  padding: 12px;
  border-radius: 50%;
}

.btn:hover .hero-section__arrow-icon {
  border: 3px solid var(--main-color);

}

@media (max-width: 680px) {
  .hero-section__slogan {
    font-size: 5rem;
  }
}

@media (max-width: 450px) {
  .hero-section__arrow-icon {
    display: none;
  }

  .hero-section__slogan {
    font-size: 3.8rem;
  }
}

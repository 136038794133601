.eurekaWhyUs__container {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  padding: 24px 18px;
  max-width: var(--container-max-width);
}

.eurekaWhyUs__phone {
  width: 40%;
}

.eurekaWhyUs__title {
  font-size: 44px;
  padding-left: 32px;
  color: var(--main-color);
  margin-bottom: 24px;
}

.eurekaWhyUs__info {
  font-size: 18px;
  margin-bottom: 32px;
  max-width: 750px;
}

.eurekaWhyUs__benefits-title {
  font-weight: 700;
  font-size: 18px;
  padding: 0;
}

.eurekaWhyUs__benefits-list {
  list-style: inside;
  font-size: 18px;
  padding-left: 28px;
  margin-bottom: 44px;
}

.eurekaWhyUs__facts-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eurekaWhyUs__facts-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}

.eurekaWhyUs__facts-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  list-style: none;
  padding: 0;
}

.eurekaWhyUs__facts-list-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
}

.eurekaWhyUs__facts-list-item-image {
  width: 40%;
}

@media (max-width: 680px) {
  .eurekaWhyUs__container {
    flex-direction: column;
  }

  .eurekaWhyUs__phone {
    width: 70%;
  }

  .eurekaWhyUs__facts-list {
    flex-direction: column;
  }
}
